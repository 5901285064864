/**
 * Colorbox Custom styles
 */
#colorbox, 
#cboxOverlay, 
#cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}

#colorbox, 
#cboxWrapper {
  overflow:visible;
}

#cboxOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.72);
}

#cboxMiddleLeft, 
#cboxBottomLeft {
  clear: left;
}

#cboxContent {
    position: relative;
    overflow: visible;

}

#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

#cboxTitle {
  margin: 0;
}

#cboxLoadingOverlay, 
#cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/**
 * These elements are buttons, and may need to have additional
 * styles reset to avoid unwanted base styles.
 */

#cboxPrevious, 
#cboxNext, 
#cboxClose, 
#cboxSlideshow {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  width: auto;
  background: none;
  cursor: pointer;
}

#cboxPrevious, 
#cboxNext {
    position: absolute;
    top: 50%;
    left: -100px;
    transform: translateY(-50%);
    font-size: 0;
    width: 30px;
    height: 21px;
}

#cboxPrevious {
    background: url(../images/arrow-l.svg) no-repeat center;
    
    @include beforebreakpoint($blg) {
        left: -30px;
    }
    
    @include beforebreakpoint($bmd) {
        left: 15px;
    }
}

#cboxNext {
    background: url(../images/arrow-r.svg) no-repeat center;
    left: auto;
    right: -100px;
    
    @include beforebreakpoint($blg) {
        left: auto;
        right: -30px;
    }
    
    @include beforebreakpoint($bmd) {
        left: auto;
        right: 15px;
    }
}

/**
 * Avoid outlines on :active (mouseclick),
 * but preserve outlines on :focus (tabbed navigating)
 */
#cboxPrevious:active, 
#cboxNext:active, 
#cboxClose:active, 
#cboxSlideshow:active {
  outline: 0;
}

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
}

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
}

/* Reset box sizing to content-box if theme is using border-box. */
#colorbox, 
#cboxContent, 
#cboxLoadedContent {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

#colorbox {
  outline: 0;
}
  
#cboxWrapper {
      
}

#cboxContent, 
.cboxPhoto {
    @include border-radius(0);
}
  
#cboxError {
    padding: 50px;
    border: 1px solid #ccc;
}
  
#cboxTitle {
    position: absolute;
    background: rgba(255, 255, 255, 0.7);
    bottom: 0;
    left: 0;
    color: #535353;
    width: 100%;
    padding: 4px 6px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

#cboxLoadingOverlay {
    background-color: transparent;
}

#cboxLoadingGraphic {
    background: url(../images/loading_animation.gif) no-repeat center center;
}

#cboxClose {
    display: none !important;
    
    .cbox-close-plain {
        position: absolute;
        font-size: 20px;
        line-height: 18px;
        text-align: center;
        color: rgba(255, 255, 255, 0.7);
        background: rgba(0, 0, 0, 0.5);
        top: 4px;
        right: 4px;
        width: 20px;
        height: 20px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }
}

.cbox-close-plain:hover {
    color: rgba(255, 255, 255, 0.9);
    background: rgba(0, 0, 0, 0.8);
}
