/* 
 User menu block styles
*/

.top-bar-user-menu-block {
    background-color: transparent;
    display: none;
    margin-top: 15px;
    
    &.open {
        display: block;
    }
    
    ul.menu {
        padding: 0;
        margin: 0;
        
        li {
            list-style: none;
            list-style-image: none;
            
            a {
                color: white;
                font-size: 1rem;
            }
        }
    }
    
    @include breakpoint($lg) {
        background-color: $c_greybgr;   
        float: right;
        clear:both;
        padding: 15px;
        position: absolute;
        right: 0;
        top: 32px;
        z-index: 10;
        margin-top: 0;
        
        ul.menu {
            
            li {     
                
                a {
                    color: $c_text;
                    font-weight: 300;
                }
            }
        }

    }

}
