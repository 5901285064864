/* Mixins */

@mixin translateX($translatex) {
  -moz-transform: translateX($translatex);
  -webkit-transform: translateX($translatex);
  -ms-transform: translateX($translatex);
  -o-transform: translateX($translatex);
  transform: translateX($translatex);
}
@mixin transition($transition) {
  -moz-transition:    all $transition ease;
  -o-transition:      all $transition ease;
  -webkit-transition: all $transition ease;
  -ms-transition: 		all $transition ease;
  transition:         all $transition ease;
}
@mixin inline-block() {
  display:-moz-inline-stack;
  display:inline-block;
  zoom:1;
  *display:inline;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}
@mixin flex() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin breakpoint($point) {
    @media only screen and (min-width: #{$point}) {
        @content;
    }
}

@mixin breakpointHeight($point) {
    @media only screen and (min-height: #{$point}) {
        @content;
    }
}

@mixin beforebreakpoint($point) {
    @media only screen and (max-width: #{$point}) {
        @content;
    }
}

@mixin betweenbreakpoints($min, $max) {
    @media (min-width: $min) and (max-width: $max) {
        @content;
    }
}