/* 
Top bar block styles
*/

#top-bar .top-bar-search-block {
    background-color: transparent;
    display: none;
    margin-top: 15px;
    
    &.open {
        display: block;
    }
    
    .form-type-textfield {
        display: inline-block;
        margin: 0 15px 0 0;
        float:left;
        width: calc(100% - 65px);

        input.form-text {
            width:100%;
            @include border-radius(4px);
            height:40px;            
            border:none;
        }
    }
    
    .form-actions {
        display: inline-block;
        margin: 0;

        input.form-submit {
            padding: .5rem .75rem;
        }
    }
    
    @include breakpoint($lg) {
        background-color: $c_greybgr;   
        float: right;
        clear:both;
        padding: 15px 25px 15px 15px;
        position: absolute;
        right: 0;
        top: 32px;
        height: 70px !important;
        z-index: 10;
        margin-top: 0;
        
        .form-type-textfield {
            display: inline-block;
            margin: 0 15px 0 0;
            float:none;
            width:auto;
            
            input.form-text {
                width:170px;
                @include border-radius(4px);
                height:40px;            
                border:none;
            }
        }
        
        .form-actions {
            display: inline-block;
            margin: 0;
            
            input.form-submit {
                background-color: black;
                border-color: black;
                color: white;
                padding: .5rem .75rem;
            }
        }
    }
   
}

