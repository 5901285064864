/* Header */

#header {
    .logo {
        display: inline-block;
        margin-top: 30px;
        
        @include breakpoint($lg) {
            margin-top: -30px;
            
        }
    }
    
    .navbar-collapse {
        display: none;
        padding: 0;
    
        @include breakpoint($lg) {
            display: block;
            float: right;
            margin-top: 30px;
        }
        
    }
}

.navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 30px;
    margin-right: 0;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 0;
    border: none;
    background: transparent !important;

    @include breakpoint($lg) {
        display: none;
    }

    .icon-bar {
        background-color: black;
        display: block;
        width: 30px;
        height: 3px;
        border-radius: 1px;
        transition: all 0.2s;

        &.top-bar {
            transform: rotate(0);
        }

        &.middle-bar {
            opacity: 1;
        }

        &.bottom-bar {
            transform: rotate(0);
        }

        +.icon-bar {
            margin-top: 6px;
        }
    }


    } //navbar-toggle

#top-bar {
    .navbar-toggle {
        position: absolute;
        bottom: 30px;
        right: 0;
        padding: 9px 10px;
        margin: 0;
        
        .icon-bar {
            background-color: white;
        }
    }
    
    &.show {
        .navbar-toggle[aria-expanded="true"] {
            .icon-bar {
                width: 34px;
                height: 2px;
                margin-top: 4px;
                
                &.top-bar {
                    transform-origin: 30% 30%;
                    transform: rotate(45deg);
                }

                &.middle-bar {
                    opacity: 0;
                }

                &.bottom-bar {
                    transform: rotate(-45deg);
                    transform-origin: 20% 20%;
                }

            }
        }
    }
}
