/* Die partner page (/node/19) styles*/

.page-node-19 {
    .node {
        .field-name-body {
            
            .row {
                
                @include beforebreakpoint($bmd) {
                    display: block;
                }
            }
            
            .col {
                margin-bottom: 30px;
                
                @include beforebreakpoint($bmd) {
                    width: 100%;
                }
            }
            
            .box {
                display: table;
                vertical-align: middle;
                max-width: 320px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                
                @include breakpoint($md) {
                    max-width: none;
                    height: 100%;
                    width: 100%;
                }
                
                a {
                    display: table-cell;
                    vertical-align: middle;
                    text-align: center;
                    text-transform: uppercase;
                
                }
                
                img {
                    display: block;
                    margin: 0 auto 10px;
                }
                
            }
        }
    }
}
