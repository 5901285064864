/* Footer */

footer {
    padding: 20px 0;
	background: $c_footerbgr;
    
    .footer-inner {
        .row {
            @include beforebreakpoint($bmd) {
                margin: 0;
            }
        }
    }
    
    .block {

    }
}