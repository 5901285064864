/* VARIABLES */
/* TYPOGRAPHY */

@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i&display=swap&subset=latin-ext');


// font families
$f_roboto: 'Roboto', sans-serif;


$font-family-base: $f_roboto;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg: 1.25rem;
$font-size-sm: .875rem;
$font-size-xs: .75rem;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;



$red: #D23434;
$light-red:#CF4242;
$blue: #009fe3;
$light-grey: #9B9B9B;
$mid-grey: #666666;
$dark-grey: #333333;
$black: #000000;
$c_text: #000;
$c_theme: #D23434;
$c_border: #c2c3c3;
$c_border-v2: #a59d95;
$c_border-v3: #ededed;
$c_greybgr:#EBEBEB;
$c_footerbgr: white;

$bxsm: 479px;
$bsm: 575px;
$bmd: 767px;
$blg: 991px;
$bxl: 1199px;

$xsm: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$navBreak: 1200px;

$enable-responsive-font-sizes: true;
