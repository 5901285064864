/* Grid overrides */
.container-fluid {
    width: 100%;
    max-width: 1320px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.container {
    @include beforebreakpoint($bmd) {
        max-width: 100%;
    }   
    
    .container {
        padding-right: 0;
        padding-left: 0;
    }
}

@include breakpoint($xl) {
    
    .row-equal {
        [class^="col-"]{
            flex: 1;
        }
    }
}