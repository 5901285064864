/* 
Newsletter signup block styles
*/

.top-bar-newsletter-block {
    background-color: transparent;
    display: none;
    margin-top: 15px;
    
    &.open {
        display: block;
    }
    
    h3 {
        color: white;
    }
    
    .mc-field-group {
        display: inline-block;
        margin: 0 15px 0 0;
        float:left;
        width: calc(100% - 65px);

        input.email {
            width:100%;
            @include border-radius(4px);
            height:40px;            
            border:none;
            font-size: 1rem;
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: 15px;
        }
    }
    
    .clear {
        display: inline-block;
        margin: 0;

        input.button {
            padding: .5rem .75rem;
        }
    }
    
    @include breakpoint($lg) {
        background-color: $c_greybgr;   
        float: right;
        clear:both;
        padding: 15px;
        position: absolute;
        right: 0;
        top: 32px;
        z-index: 10;
        margin-top: 0;
        
        h3 {
            color: $c_text;
        }
        
        .mc-field-group {
            display: inline-block;
            margin: 0 15px 0 0;
            width:260px;
            
            input.email {
                width:100%;           
                border:none;
                margin-bottom: 0;
            }
        }
        
        .clear {
            
            input.button {
                background-color: black;
                border-color: black;
                color: white;
                padding: .5rem .75rem;
            }
        }
    }
    
        #mc_embed_signup div.mce_inline_error {
            margin: 0;
            padding: 5px;
            font-size: 1rem;
            background-color: transparent;
            font-weight: 600;
            z-index: 1;
            color: #fff;

            @include breakpoint($lg) {
                color: $light_red;
            }
        }

}
