/* View gallery */

.view-gallery {
    
    .gallery-item {
        margin-bottom: 30px;
        text-align: center;
        
        @include breakpoint($lg) {

        }
    }
    
    .gallery-wrapper {
    $custom-breakpoint: 399px;
        
        position: relative;
        display: inline-block;
        
        &:after {
            content: "";
            display: block;
            width: 15px;
            height: 18px;
            background: url(../images/zoom.svg) no-repeat center;
            background-size: cover;
            position: absolute;
            bottom: 10px;
            right: 15px;
            
            @include beforebreakpoint($custom-breakpoint) {
                background: none;
            }
        }
    }
}
