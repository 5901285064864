/* 
Mobile menu block styles
*/

.top-bar-mobile-menu-block {
   background-color: transparent;
    
    @include breakpoint($lg) {
        display: none;
    }
    
    ul.menu {
        
        margin: 0;
        padding: 15px 0;
        
        li {
            list-style: none;
            list-style-image: none;
            margin: 0;
            padding: 0;
            display: block;
            
            a {
                padding: 6px 0;
                color: white;
                display: block;
            }
        }
    }
    
    ul.dropdown-menu {
        background: none;
        position: relative !important;
        transform: none !important;
        width: 100%;
        border: none;
        margin: 0;
        padding: 5px 0;
    }
}
