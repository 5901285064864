/* Landing page content type styles*/
.node-type-landing-page {
    .region-content-wrapper {
        padding: 0;
        
        @include beforebreakpoint($bmd) {
            padding: 0;
        }
    }
    
    .main-container {
        > .container {
            max-width: none;
            width: 100%;
        }
    }
}
