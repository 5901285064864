/* Primary tabs */
$custom-bgr-color: #f6f6f6;

.primary-tabs-wrapper{
    
    ul.tabs.primary {
        padding: 0;
        margin: 10px 0 15px;
        width: 100%;
        
        @include beforebreakpoint($bmd) {
            border: none;
            margin: 20px 0 10px;
        }
        
        li {
            
            @include beforebreakpoint($bmd) {
                display: block;
                margin: 0 0 10px;
            }
            
            a {
                font-size: 1rem;
                font-weight: 300;
                background-color: $custom-bgr-color;
                color: black;
                transition: color ease .3s;
                
                @include beforebreakpoint($bmd) {
                    margin: 0;
                    padding: 5px 8px;
                    display: block;
                    border: solid 1px $light-grey;
                    @include border-radius(5px);
                }
                
            }
            
            &:hover,
            &.active {
                a {
                    background-color: white;
                    color: $c_theme;
                }
            }
        }
    }
}