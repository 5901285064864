/* Homepage */
//Four big buttons on top of the content section
.path-frontpage {
    .node__content {
        >.layout--fourcol-section {
            .block {            
                p {
                    margin: 0;
                }
            }
        }
    }
    
    .full-width.background-light-blue + .layout--onecol {
        margin-bottom: 0;
        
        & +.full-width.background-light-blue {
            margin-top: -30px;
        }
    }
}
