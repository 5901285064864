/* 
Theme Name: Acquire
Description: Classy sub-theme with Sass & Bootstrap 4
*/
/* IMPORTS */
/* VARIABLES */
/* TYPOGRAPHY */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i&display=swap&subset=latin-ext");
/* Mixins */
/* Grid overrides */
.container-fluid {
  width: 100%;
  max-width: 1320px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media only screen and (max-width: 767px) {
  .container {
    max-width: 100%; } }

.container .container {
  padding-right: 0;
  padding-left: 0; }

@media only screen and (min-width: 1200px) {
  .row-equal [class^="col-"] {
    flex: 1; } }

/* Header */
#header .logo {
  display: inline-block;
  margin-top: 30px; }
  @media only screen and (min-width: 992px) {
    #header .logo {
      margin-top: -30px; } }

#header .navbar-collapse {
  display: none;
  padding: 0; }
  @media only screen and (min-width: 992px) {
    #header .navbar-collapse {
      display: block;
      float: right;
      margin-top: 30px; } }

.navbar-toggle {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-top: 30px;
  margin-right: 0;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 0;
  border: none;
  background: transparent !important; }
  @media only screen and (min-width: 992px) {
    .navbar-toggle {
      display: none; } }
  .navbar-toggle .icon-bar {
    background-color: black;
    display: block;
    width: 30px;
    height: 3px;
    border-radius: 1px;
    transition: all 0.2s; }
    .navbar-toggle .icon-bar.top-bar {
      transform: rotate(0); }
    .navbar-toggle .icon-bar.middle-bar {
      opacity: 1; }
    .navbar-toggle .icon-bar.bottom-bar {
      transform: rotate(0); }
    .navbar-toggle .icon-bar + .icon-bar {
      margin-top: 6px; }

#top-bar .navbar-toggle {
  position: absolute;
  bottom: 30px;
  right: 0;
  padding: 9px 10px;
  margin: 0; }
  #top-bar .navbar-toggle .icon-bar {
    background-color: white; }

#top-bar.show .navbar-toggle[aria-expanded="true"] .icon-bar {
  width: 34px;
  height: 2px;
  margin-top: 4px; }
  #top-bar.show .navbar-toggle[aria-expanded="true"] .icon-bar.top-bar {
    transform-origin: 30% 30%;
    transform: rotate(45deg); }
  #top-bar.show .navbar-toggle[aria-expanded="true"] .icon-bar.middle-bar {
    opacity: 0; }
  #top-bar.show .navbar-toggle[aria-expanded="true"] .icon-bar.bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 20% 20%; }

/* Primary menu */
#navbar-collapse ul.menu {
  margin: 0;
  padding: 0; }
  #navbar-collapse ul.menu > li {
    list-style: none;
    list-style-image: none;
    padding: 0;
    margin: 0 1rem 0 0; }
    #navbar-collapse ul.menu > li.last {
      margin-right: 0; }
    #navbar-collapse ul.menu > li a, #navbar-collapse ul.menu > li span {
      font-size: 1.375rem;
      font-weight: 300;
      color: black;
      transition: color ease .23s; }
      #navbar-collapse ul.menu > li a:hover, #navbar-collapse ul.menu > li a:focus, #navbar-collapse ul.menu > li a.active, #navbar-collapse ul.menu > li span:hover, #navbar-collapse ul.menu > li span:focus, #navbar-collapse ul.menu > li span.active {
        color: #9B9B9B; }
    #navbar-collapse ul.menu > li span {
      cursor: default; }
    @media only screen and (min-width: 768px) {
      #navbar-collapse ul.menu > li.dropdown:hover ul.dropdown-menu {
        display: block; } }
    #navbar-collapse ul.menu > li.dropdown.show a.dropdown-toggle:after {
      transform: rotate(-180deg); }
    #navbar-collapse ul.menu > li ul.dropdown-menu {
      border: none;
      background: white;
      padding: 10px 0;
      position: relative; }
      @media only screen and (min-width: 992px) {
        #navbar-collapse ul.menu > li ul.dropdown-menu {
          position: absolute;
          margin: 0; } }
      #navbar-collapse ul.menu > li ul.dropdown-menu li {
        list-style: none;
        list-style-image: none;
        margin: 0;
        padding: 0; }
        #navbar-collapse ul.menu > li ul.dropdown-menu li a {
          font-size: 0.9375rem;
          display: block;
          padding: 5px 0; }
    #navbar-collapse ul.menu > li.dropdown.show ul.dropdown-menu {
      display: block; }
  #navbar-collapse ul.menu li.dropdown-item a {
    transition: none; }
    #navbar-collapse ul.menu li.dropdown-item a:hover {
      background: none; }
  #navbar-collapse ul.menu li.dropdown-item:hover {
    background: none; }
  #navbar-collapse ul.menu li.dropdown-item.active {
    background: none; }
  @media only screen and (min-width: 992px) {
    #navbar-collapse ul.menu {
      width: 100%;
      flex-direction: row; }
      #navbar-collapse ul.menu > li {
        display: inline-block; }
        #navbar-collapse ul.menu > li:last-child {
          margin: 0; } }

/* Footer */
footer {
  padding: 20px 0;
  background: white; }
  @media only screen and (max-width: 767px) {
    footer .footer-inner .row {
      margin: 0; } }

/* Form */
.form-control, .form-text,
.form-select,
.form-search,
.form-email,
.form-tel {
  height: 34px;
  padding: 8px 15px;
  font-size: 1rem;
  line-height: 19px;
  color: #000;
  box-sizing: border-box;
  border: 1px solid #a59d95;
  border-radius: 0;
  transition: none;
  font-weight: 400; }
  .form-control:hover, .form-text:hover,
  .form-select:hover,
  .form-search:hover,
  .form-email:hover,
  .form-tel:hover, .form-control:focus, .form-text:focus,
  .form-select:focus,
  .form-search:focus,
  .form-email:focus,
  .form-tel:focus {
    box-shadow: none; }

.form-text,
.form-select,
.form-search,
.form-email,
.form-tel {
  display: block;
  width: 100%;
  margin: 0;
  font-size: 1rem; }

.form-select {
  cursor: pointer;
  padding: 4px 15px; }

textarea {
  font-size: 1rem;
  font-weight: 400;
  padding: 10px; }

.form-type-radio input[type="radio"],
.form-type-radio input[type="checkbox"] {
  margin: 5px; }

.form-type-radio label {
  max-width: 90%;
  width: auto !important; }

@media only screen and (max-width: 767px) {
  .webform-element--title-inline > label {
    margin-bottom: 5px;
    display: block; } }

label {
  display: block;
  margin: 0;
  font-weight: 300;
  font-size: 1rem; }

.form-item legend {
  font-weight: 600;
  font-size: 1.286rem; }

.form-item,
.form-actions {
  margin: 15px 0; }

.button,
.btn,
.btn-2x,
.webform-submission-form .form-actions .webform-button--submit,
.form-submit,
#popup-buttons button {
  background-color: white;
  color: #CF4242;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #CF4242;
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  line-height: 1.42857143;
  text-transform: none;
  outline: none;
  cursor: pointer; }
  .button:hover, .button:focus,
  .btn:hover,
  .btn-2x:hover,
  .webform-submission-form .form-actions .webform-button--submit:hover,
  .btn:focus,
  .btn-2x:focus,
  .webform-submission-form .form-actions .webform-button--submit:focus,
  .form-submit:hover,
  .form-submit:focus,
  #popup-buttons button:hover,
  #popup-buttons button:focus {
    background-color: white;
    color: #CF4242;
    text-decoration: none;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.5); }
  @media only screen and (max-width: 768px) {
    .button,
    .btn,
    .btn-2x,
    .webform-submission-form .form-actions .webform-button--submit,
    .form-submit,
    #popup-buttons button {
      margin-bottom: 15px; } }

.button + .button,
.btn + .btn,
.btn-2x + .btn,
.webform-submission-form .form-actions .webform-button--submit + .btn,
.btn + .btn-2x,
.btn-2x + .btn-2x,
.webform-submission-form .form-actions .webform-button--submit + .btn-2x,
.webform-submission-form .form-actions .btn + .webform-button--submit,
.webform-submission-form .form-actions .btn-2x + .webform-button--submit,
.webform-submission-form .form-actions .webform-button--submit + .webform-button--submit,
.button + .button + .button,
.btn + .btn + .btn,
.btn-2x + .btn + .btn,
.webform-submission-form .form-actions .webform-button--submit + .btn + .btn,
.btn + .btn-2x + .btn,
.btn-2x + .btn-2x + .btn,
.webform-submission-form .form-actions .webform-button--submit + .btn-2x + .btn,
.webform-submission-form .form-actions .btn + .webform-button--submit + .btn,
.webform-submission-form .form-actions .btn-2x + .webform-button--submit + .btn,
.webform-submission-form .form-actions .webform-button--submit + .webform-button--submit + .btn,
.btn + .btn + .btn-2x,
.btn-2x + .btn + .btn-2x,
.webform-submission-form .form-actions .webform-button--submit + .btn + .btn-2x,
.btn + .btn-2x + .btn-2x,
.btn-2x + .btn-2x + .btn-2x,
.webform-submission-form .form-actions .webform-button--submit + .btn-2x + .btn-2x,
.webform-submission-form .form-actions .btn + .webform-button--submit + .btn-2x,
.webform-submission-form .form-actions .btn-2x + .webform-button--submit + .btn-2x,
.webform-submission-form .form-actions .webform-button--submit + .webform-button--submit + .btn-2x,
.webform-submission-form .form-actions .btn + .btn + .webform-button--submit,
.webform-submission-form .form-actions .btn-2x + .btn + .webform-button--submit,
.webform-submission-form .form-actions .webform-button--submit + .btn + .webform-button--submit,
.webform-submission-form .form-actions .btn + .btn-2x + .webform-button--submit,
.webform-submission-form .form-actions .btn-2x + .btn-2x + .webform-button--submit,
.webform-submission-form .form-actions .webform-button--submit + .btn-2x + .webform-button--submit,
.webform-submission-form .form-actions .btn + .webform-button--submit + .webform-button--submit,
.webform-submission-form .form-actions .btn-2x + .webform-button--submit + .webform-button--submit,
.webform-submission-form .form-actions .webform-button--submit + .webform-button--submit + .webform-button--submit {
  margin-left: 10px; }

.btn-2x {
  text-align: center;
  font-size: 1.286rem;
  font-weight: 600;
  line-height: 1.42;
  padding: 6px 12px; }

.btn-white, .page-search .search-form input[type="submit"] {
  font-weight: 400; }
  .btn-white:hover, .page-search .search-form input:hover[type="submit"], .btn-white:focus, .page-search .search-form input:focus[type="submit"] {
    background-color: #e6e6e6;
    color: #000;
    box-shadow: none;
    text-decoration: none; }

nav.pager {
  margin: 0 0 15px; }
  nav.pager .pager__items {
    text-align: right;
    margin: 0;
    padding: 0; }
    nav.pager .pager__items li.pager__item {
      font-size: 1rem;
      font-weight: 400;
      border: 1px solid #9B9B9B;
      margin-right: 3px;
      margin-bottom: 10px;
      padding: 5px 10px;
      display: inline-block;
      background-color: white; }
      nav.pager .pager__items li.pager__item:hover {
        background-color: #D23434; }
      nav.pager .pager__items li.pager__item.is-active {
        font-weight: 600;
        cursor: default; }
      nav.pager .pager__items li.pager__item.pager__item--next, nav.pager .pager__items li.pager__item.pager__item--previous, nav.pager .pager__items li.pager__item.pager__item--last {
        background: white;
        padding: 0; }
        nav.pager .pager__items li.pager__item.pager__item--next:hover, nav.pager .pager__items li.pager__item.pager__item--previous:hover, nav.pager .pager__items li.pager__item.pager__item--last:hover {
          background-color: #D23434; }
        nav.pager .pager__items li.pager__item.pager__item--next a, nav.pager .pager__items li.pager__item.pager__item--previous a, nav.pager .pager__items li.pager__item.pager__item--last a {
          background: transparent;
          color: #009fe3;
          padding: 5px 10px;
          display: inline-block; }

.page-search .search-form input[type="text"] {
  max-width: 250px;
  height: 36px;
  display: inline-block; }

.page-search .search-form .search-help-link {
  margin: 10px 0;
  display: inline-block; }

@media only screen and (max-width: 575px) {
  .page-search .search-form label {
    display: block;
    margin-bottom: 5px; } }

.page-search .item-list {
  margin-bottom: 30px; }

.page-search .search-advanced summary {
  padding: 10px; }

.page-search .search-form + h2 {
  margin: 30px 0 20px; }

.page-search .search-results {
  padding: 0; }
  .page-search .search-results li {
    margin: 0 0 15px;
    padding: 0 0 14px;
    border-bottom: solid 1px #ededed; }
  .page-search .search-results .search-info span.username {
    display: none; }

.webform-submission-form legend {
  margin: 0 0 15px; }

.webform-submission-form .form-item {
  margin: 0 0 15px 0; }

@media only screen and (min-width: 768px) {
  .webform-submission-form .webform-element--title-inline {
    display: flex; } }

@media only screen and (min-width: 768px) {
  .webform-submission-form .webform-element--title-inline.radios--wrapper {
    display: block; } }

.webform-submission-form .webform-element--title-inline.radios--wrapper legend,
.webform-submission-form .webform-element--title-inline.radios--wrapper .fieldset-wrapper {
  float: left;
  margin: 10px 0;
  font-weight: 300; }
  @media only screen and (min-width: 768px) {
    .webform-submission-form .webform-element--title-inline.radios--wrapper legend,
    .webform-submission-form .webform-element--title-inline.radios--wrapper .fieldset-wrapper {
      width: 50%;
      display: inline-block; } }

.webform-submission-form .form-item.form-type-textarea {
  display: block; }

.webform-submission-form .form-actions {
  text-align: right;
  padding-bottom: 30px; }
  .webform-submission-form .form-actions .webform-button--submit:hover, .webform-submission-form .form-actions .webform-button--submit:focus {
    background-color: #000;
    border-color: #000; }

@media only screen and (min-width: 768px) {
  .webform-submission-form .webform-element--title-inline .form-text,
  .webform-submission-form .webform-element--title-inline .form-select,
  .webform-submission-form .webform-element--title-inline .form-email,
  .webform-submission-form .webform-element--title-inline .form-tel {
    width: 50%;
    display: inline-block; } }

.webform-submission-form .form-type-checkbox {
  display: block; }
  .webform-submission-form .form-type-checkbox .description {
    margin: 10px 0 0; }

.webform-submission-form .form-item label {
  margin-bottom: 5px; }

@media only screen and (min-width: 768px) {
  .webform-submission-form .form-item.webform-element--title-inline label {
    width: 50%;
    display: inline-block; } }

.webform-submission-form .form-type-textarea label {
  width: 100%;
  display: block; }

.form-item .description {
  font-size: 0.875rem;
  margin: 10px 0 0; }

/* Homepage */
.path-frontpage .node__content > .layout--fourcol-section .block p {
  margin: 0; }

.path-frontpage .full-width.background-light-blue + .layout--onecol {
  margin-bottom: 0; }
  .path-frontpage .full-width.background-light-blue + .layout--onecol + .full-width.background-light-blue {
    margin-top: -30px; }

/* Block main menu */
.block-menu.menu--main {
  display: flex; }

/* Block local tasks */
.block-local-tasks-block {
  margin: 30px 0 15px; }
  .block-local-tasks-block ul.tabs.primary li a {
    font-size: 16px; }

/* Block system main block */
@media only screen and (min-width: 992px) {
  .block-system-main-block > .container-fluid {
    padding-top: 20px; } }

.page-node-civi_fed_organisation .block-system-main-block > .container-fluid {
  padding-top: 0; }

/* 
Iconized links block styles
*/
.top-bar-iconized-links-block {
  background-color: transparent; }
  @media only screen and (min-width: 992px) {
    .top-bar-iconized-links-block {
      background-color: white; } }
  .top-bar-iconized-links-block .social-links {
    float: left;
    display: inline-block; }
    .top-bar-iconized-links-block .social-links a {
      font-size: 1.5rem;
      line-height: 1.5rem;
      color: white;
      margin-right: 15px;
      display: inline-block; }
      .top-bar-iconized-links-block .social-links a:last-child {
        margin: 0; }
      .top-bar-iconized-links-block .social-links a.fb-link {
        width: 22px;
        height: 24px;
        position: relative; }
        .top-bar-iconized-links-block .social-links a.fb-link .fab {
          position: absolute;
          top: 4px; }
          .top-bar-iconized-links-block .social-links a.fb-link .fab:before {
            display: inline-block; }
      .top-bar-iconized-links-block .social-links a.ig-link {
        font-size: 1.65rem;
        line-height: 1.2;
        position: relative;
        width: 30px;
        height: 24px; }
        .top-bar-iconized-links-block .social-links a.ig-link .fab {
          position: absolute;
          top: 4px; }
          .top-bar-iconized-links-block .social-links a.ig-link .fab:before {
            display: inline-block; }
    @media only screen and (min-width: 992px) {
      .top-bar-iconized-links-block .social-links {
        float: none;
        margin-right: 45px; }
        .top-bar-iconized-links-block .social-links a {
          color: black; } }
  .top-bar-iconized-links-block .user-links {
    float: right;
    display: inline-block; }
    .top-bar-iconized-links-block .user-links a {
      display: inline-block;
      margin-right: 15px; }
      .top-bar-iconized-links-block .user-links a:last-child {
        margin: 0; }
      .top-bar-iconized-links-block .user-links a.user-link-toggle {
        margin-right: 5px;
        width: 40px;
        text-align: center; }
      .top-bar-iconized-links-block .user-links a.newsletter-link {
        margin-right: 5px;
        width: 40px;
        text-align: center; }
      .top-bar-iconized-links-block .user-links a.search-link {
        width: 36px;
        text-align: center; }
      @media only screen and (min-width: 992px) {
        .top-bar-iconized-links-block .user-links a.user-link-toggle.active, .top-bar-iconized-links-block .user-links a.newsletter-link.active, .top-bar-iconized-links-block .user-links a.search-link.active {
          background: #EBEBEB; } }
    .top-bar-iconized-links-block .user-links span.desktop-only {
      display: none; }
    @media only screen and (min-width: 992px) {
      .top-bar-iconized-links-block .user-links {
        float: none; }
        .top-bar-iconized-links-block .user-links span.desktop-only {
          display: inline-block; }
        .top-bar-iconized-links-block .user-links span.mobile-only {
          display: none; } }
  @media only screen and (min-width: 992px) {
    .top-bar-iconized-links-block {
      float: right; } }

/* 
Mobile menu block styles
*/
.top-bar-mobile-menu-block {
  background-color: transparent; }
  @media only screen and (min-width: 992px) {
    .top-bar-mobile-menu-block {
      display: none; } }
  .top-bar-mobile-menu-block ul.menu {
    margin: 0;
    padding: 15px 0; }
    .top-bar-mobile-menu-block ul.menu li {
      list-style: none;
      list-style-image: none;
      margin: 0;
      padding: 0;
      display: block; }
      .top-bar-mobile-menu-block ul.menu li a {
        padding: 6px 0;
        color: white;
        display: block; }
  .top-bar-mobile-menu-block ul.dropdown-menu {
    background: none;
    position: relative !important;
    transform: none !important;
    width: 100%;
    border: none;
    margin: 0;
    padding: 5px 0; }

/* 
Top bar block styles
*/
#top-bar .top-bar-search-block {
  background-color: transparent;
  display: none;
  margin-top: 15px; }
  #top-bar .top-bar-search-block.open {
    display: block; }
  #top-bar .top-bar-search-block .form-type-textfield {
    display: inline-block;
    margin: 0 15px 0 0;
    float: left;
    width: calc(100% - 65px); }
    #top-bar .top-bar-search-block .form-type-textfield input.form-text {
      width: 100%;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      border-radius: 4px;
      height: 40px;
      border: none; }
  #top-bar .top-bar-search-block .form-actions {
    display: inline-block;
    margin: 0; }
    #top-bar .top-bar-search-block .form-actions input.form-submit {
      padding: .5rem .75rem; }
  @media only screen and (min-width: 992px) {
    #top-bar .top-bar-search-block {
      background-color: #EBEBEB;
      float: right;
      clear: both;
      padding: 15px 25px 15px 15px;
      position: absolute;
      right: 0;
      top: 32px;
      height: 70px !important;
      z-index: 10;
      margin-top: 0; }
      #top-bar .top-bar-search-block .form-type-textfield {
        display: inline-block;
        margin: 0 15px 0 0;
        float: none;
        width: auto; }
        #top-bar .top-bar-search-block .form-type-textfield input.form-text {
          width: 170px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          border-radius: 4px;
          height: 40px;
          border: none; }
      #top-bar .top-bar-search-block .form-actions {
        display: inline-block;
        margin: 0; }
        #top-bar .top-bar-search-block .form-actions input.form-submit {
          background-color: black;
          border-color: black;
          color: white;
          padding: .5rem .75rem; } }

/* 
 User menu block styles
*/
.top-bar-user-menu-block {
  background-color: transparent;
  display: none;
  margin-top: 15px; }
  .top-bar-user-menu-block.open {
    display: block; }
  .top-bar-user-menu-block ul.menu {
    padding: 0;
    margin: 0; }
    .top-bar-user-menu-block ul.menu li {
      list-style: none;
      list-style-image: none; }
      .top-bar-user-menu-block ul.menu li a {
        color: white;
        font-size: 1rem; }
  @media only screen and (min-width: 992px) {
    .top-bar-user-menu-block {
      background-color: #EBEBEB;
      float: right;
      clear: both;
      padding: 15px;
      position: absolute;
      right: 0;
      top: 32px;
      z-index: 10;
      margin-top: 0; }
      .top-bar-user-menu-block ul.menu li a {
        color: #000;
        font-weight: 300; } }

/* 
Newsletter signup block styles
*/
.top-bar-newsletter-block {
  background-color: transparent;
  display: none;
  margin-top: 15px; }
  .top-bar-newsletter-block.open {
    display: block; }
  .top-bar-newsletter-block h3 {
    color: white; }
  .top-bar-newsletter-block .mc-field-group {
    display: inline-block;
    margin: 0 15px 0 0;
    float: left;
    width: calc(100% - 65px); }
    .top-bar-newsletter-block .mc-field-group input.email {
      width: 100%;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      border-radius: 4px;
      height: 40px;
      border: none;
      font-size: 1rem;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 15px; }
  .top-bar-newsletter-block .clear {
    display: inline-block;
    margin: 0; }
    .top-bar-newsletter-block .clear input.button {
      padding: .5rem .75rem; }
  @media only screen and (min-width: 992px) {
    .top-bar-newsletter-block {
      background-color: #EBEBEB;
      float: right;
      clear: both;
      padding: 15px;
      position: absolute;
      right: 0;
      top: 32px;
      z-index: 10;
      margin-top: 0; }
      .top-bar-newsletter-block h3 {
        color: #000; }
      .top-bar-newsletter-block .mc-field-group {
        display: inline-block;
        margin: 0 15px 0 0;
        width: 260px; }
        .top-bar-newsletter-block .mc-field-group input.email {
          width: 100%;
          border: none;
          margin-bottom: 0; }
      .top-bar-newsletter-block .clear input.button {
        background-color: black;
        border-color: black;
        color: white;
        padding: .5rem .75rem; } }
  .top-bar-newsletter-block #mc_embed_signup div.mce_inline_error {
    margin: 0;
    padding: 5px;
    font-size: 1rem;
    background-color: transparent;
    font-weight: 600;
    z-index: 1;
    color: #fff; }
    @media only screen and (min-width: 992px) {
      .top-bar-newsletter-block #mc_embed_signup div.mce_inline_error {
        color: #CF4242; } }

/* 
Region top bar styles
*/
#top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: white;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all ease-in-out .23s;
  padding: 30px 0 0; }
  #top-bar .region {
    padding: 0;
    position: relative; }
  @media only screen and (min-width: 992px) {
    #top-bar {
      padding: 40px 0 0;
      display: block;
      position: relative;
      background-color: transparent;
      height: auto;
      opacity: 1;
      z-index: auto;
      overflow: inherit; } }
  #top-bar.show {
    height: 100%;
    opacity: 1;
    background-color: #D23434; }
  @media only screen and (min-width: 992px) {
    #top-bar .close-wrapper {
      display: none; } }
  #top-bar .container,
  #top-bar .row,
  #top-bar .col-12,
  #top-bar .region {
    height: 100%; }
  #top-bar .block {
    height: auto; }
    #top-bar .block.top-bar-mobile-menu-block {
      padding-top: 100px;
      width: 100%; }

/* Landing page content type styles*/
.node-type-landing-page .region-content-wrapper {
  padding: 0; }
  @media only screen and (max-width: 767px) {
    .node-type-landing-page .region-content-wrapper {
      padding: 0; } }

.node-type-landing-page .main-container > .container {
  max-width: none;
  width: 100%; }

/* Landing page content type styles*/
.node-type-stadtnaturevent .region-content-wrapper {
  padding: 0; }
  @media only screen and (max-width: 767px) {
    .node-type-stadtnaturevent .region-content-wrapper {
      padding: 0; } }

.node-type-stadtnaturevent .main-container > .container {
  max-width: none;
  width: 100%; }

.event-ajax-container {
  background-color: white;
  opacity: 0;
  transition: opacity ease .23s; }
  .event-ajax-container.open {
    height: calc(100% - 244px);
    position: absolute;
    top: 244px;
    left: 0;
    width: 100%;
    opacity: 1;
    z-index: 10; }

.node-stadtnaturevent .event-top .container,
.node-stadtnaturevent .event-middle .container,
.node-stadtnaturevent .event-bottom .container {
  padding-left: 15px;
  padding-right: 15px; }

.node-stadtnaturevent .event-top {
  padding: 30px 0; }
  .node-stadtnaturevent .event-top .col-12 {
    padding-bottom: 15px;
    text-align: right; }
    .node-stadtnaturevent .event-top .col-12 a.ajax_event-window-close {
      display: inline-block;
      color: black;
      font-size: 1rem;
      font-weight: 300; }
      .node-stadtnaturevent .event-top .col-12 a.ajax_event-window-close .mobile-close,
      .node-stadtnaturevent .event-top .col-12 a.ajax_event-window-close .desktop-close {
        display: none; }
      @media only screen and (max-width: 767px) {
        .node-stadtnaturevent .event-top .col-12 a.ajax_event-window-close .mobile-close {
          display: inline-block; } }
      @media only screen and (min-width: 768px) {
        .node-stadtnaturevent .event-top .col-12 a.ajax_event-window-close .desktop-close {
          display: inline-block; } }
  @media only screen and (max-width: 767px) {
    .node-stadtnaturevent .event-top .col-left {
      margin-bottom: 20px; } }
  .node-stadtnaturevent .event-top .col-left .event-node-date-wrapper {
    font-size: 1.625rem;
    font-weight: 300;
    text-transform: uppercase;
    color: #CF4242;
    margin-bottom: 20px; }
  .node-stadtnaturevent .event-top .col-left h1 {
    font-size: 2.25rem; }
    @media only screen and (min-width: 992px) {
      .node-stadtnaturevent .event-top .col-left h1 {
        font-size: 2.5rem; } }
  .node-stadtnaturevent .event-top .col-left .event-price-wrapper,
  .node-stadtnaturevent .event-top .col-left .event-contact-info-wrapper,
  .node-stadtnaturevent .event-top .col-left .event-contact-wrapper,
  .node-stadtnaturevent .event-top .col-left .event-place-wrapper {
    font-size: 1rem;
    font-weight: 400; }
    .node-stadtnaturevent .event-top .col-left .event-price-wrapper .field-label,
    .node-stadtnaturevent .event-top .col-left .event-contact-info-wrapper .field-label,
    .node-stadtnaturevent .event-top .col-left .event-contact-wrapper .field-label,
    .node-stadtnaturevent .event-top .col-left .event-place-wrapper .field-label {
      font-weight: 500; }
  .node-stadtnaturevent .event-top .single-column .col-left {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%; }

.node-stadtnaturevent .event-middle {
  padding: 30px 0;
  background-color: #EBEBEB; }
  .node-stadtnaturevent .event-middle p.event-name {
    font-size: 1.25rem;
    text-transform: uppercase; }
    @media only screen and (min-width: 992px) {
      .node-stadtnaturevent .event-middle p.event-name {
        font-size: 1.375rem; } }
  .node-stadtnaturevent .event-middle h3.event-subtitle {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 20px; }
    @media only screen and (min-width: 992px) {
      .node-stadtnaturevent .event-middle h3.event-subtitle {
        font-size: 1.375rem; } }
  .node-stadtnaturevent .event-middle .event-description {
    font-size: 1.25rem;
    letter-spacing: 0.25px; }
    @media only screen and (min-width: 992px) {
      .node-stadtnaturevent .event-middle .event-description {
        font-size: 1.375rem; } }

.node-stadtnaturevent .event-bottom {
  padding: 30px 0; }
  .node-stadtnaturevent .event-bottom .event-link {
    font-size: 1rem;
    margin-bottom: 15px; }
    .node-stadtnaturevent .event-bottom .event-link a {
      color: black;
      font-weight: 300;
      text-decoration: underline; }

/* Die partner page (/node/19) styles*/
@media only screen and (max-width: 767px) {
  .page-node-19 .node .field-name-body .row {
    display: block; } }

.page-node-19 .node .field-name-body .col {
  margin-bottom: 30px; }
  @media only screen and (max-width: 767px) {
    .page-node-19 .node .field-name-body .col {
      width: 100%; } }

.page-node-19 .node .field-name-body .box {
  display: table;
  vertical-align: middle;
  max-width: 320px;
  width: 100%;
  margin-left: auto;
  margin-right: auto; }
  @media only screen and (min-width: 768px) {
    .page-node-19 .node .field-name-body .box {
      max-width: none;
      height: 100%;
      width: 100%; } }
  .page-node-19 .node .field-name-body .box a {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-transform: uppercase; }
  .page-node-19 .node .field-name-body .box img {
    display: block;
    margin: 0 auto 10px; }

/* Paragraphs styles */
.paragraphs-items .container.full_width {
  width: 100%;
  max-width: none; }

.field-name-field-columns .column-items {
  margin: 0 auto; }
  .field-name-field-columns .column-items .column {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto; }
    .field-name-field-columns .column-items .column:last-child {
      margin-bottom: 0; }

.field-name-field-two-columns .column-items {
  margin: 0 auto; }
  .field-name-field-two-columns .column-items .column {
    padding-left: 15px;
    padding-right: 15px; }
    .field-name-field-two-columns .column-items .column:last-child {
      margin-bottom: 0; }
    .field-name-field-two-columns .column-items .column .entity,
    .field-name-field-two-columns .column-items .column .content {
      position: relative;
      height: 100%; }

@media (min-width: 768px) and (max-width: 991px) {
  .paragraphs-item-section-even-sized-columns.columns-2 .column {
    width: 50%; }
  .paragraphs-item-section-even-sized-columns.columns-3 .column {
    width: 33.33333%; }
  .paragraphs-item-section-even-sized-columns.columns-4 .column {
    width: 50%; }
  .paragraphs-item-section-even-sized-columns.columns-5 .column,
  .paragraphs-item-section-even-sized-columns.columns-6 .column {
    width: 33.33333%; } }

@media only screen and (min-width: 992px) {
  .paragraphs-item-section-even-sized-columns.columns-2 .column {
    width: 50%; }
  .paragraphs-item-section-even-sized-columns.columns-3 .column {
    width: 33.33333%; }
  .paragraphs-item-section-even-sized-columns.columns-4 .column {
    width: 25%; }
  .paragraphs-item-section-even-sized-columns.columns-5 .column {
    width: 20%; }
  .paragraphs-item-section-even-sized-columns.columns-6 .column {
    width: 16.6666666%; } }

@media only screen and (min-width: 768px) {
  .paragraphs-item-section-even-sized-columns.columns-1.full_width .field-name-field-columns {
    max-width: 720px; } }

@media only screen and (min-width: 992px) {
  .paragraphs-item-section-even-sized-columns.columns-1.full_width .field-name-field-columns {
    max-width: 960px; } }

@media only screen and (min-width: 1200px) {
  .paragraphs-item-section-even-sized-columns.columns-1.full_width .field-name-field-columns {
    max-width: 1140px; } }

.paragraphs-item-section-even-sized-columns.columns-1.full_width .field-name-field-columns .column {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0;
  margin-right: auto;
  margin-left: auto; }

@media only screen and (min-width: 992px) {
  .paragraphs-item-two-columns-variable-widths.fc-width-1 .column.even {
    width: 8.333333%; }
  .paragraphs-item-two-columns-variable-widths.fc-width-2 .column.even {
    width: 16.666667%; }
  .paragraphs-item-two-columns-variable-widths.fc-width-3 .column.even {
    width: 25%; }
  .paragraphs-item-two-columns-variable-widths.fc-width-4 .column.even {
    width: 33.333333%; }
  .paragraphs-item-two-columns-variable-widths.fc-width-5 .column.even {
    width: 41.666667%; }
  .paragraphs-item-two-columns-variable-widths.fc-width-6 .column.even {
    width: 50%; }
  .paragraphs-item-two-columns-variable-widths.fc-width-7 .column.even {
    width: 58.333333%; }
  .paragraphs-item-two-columns-variable-widths.fc-width-8 .column.even {
    width: 66.666667%; }
  .paragraphs-item-two-columns-variable-widths.fc-width-9 .column.even {
    width: 75%; }
  .paragraphs-item-two-columns-variable-widths.fc-width-10 .column.even {
    width: 83.333333%; }
  .paragraphs-item-two-columns-variable-widths.fc-width-11 .column.even {
    width: 91.666667%; }
  .paragraphs-item-two-columns-variable-widths.fc-width-12 .column, .paragraphs-item-two-columns-variable-widths.sc-width-12 .column {
    width: 100%; }
  .paragraphs-item-two-columns-variable-widths.sc-width-1 .column.odd {
    width: 8.333333%; }
  .paragraphs-item-two-columns-variable-widths.sc-width-2 .column.odd {
    width: 16.666667%; }
  .paragraphs-item-two-columns-variable-widths.sc-width-3 .column.odd {
    width: 25%; }
  .paragraphs-item-two-columns-variable-widths.sc-width-4 .column.odd {
    width: 33.333333%; }
  .paragraphs-item-two-columns-variable-widths.sc-width-5 .column.odd {
    width: 41.666667%; }
  .paragraphs-item-two-columns-variable-widths.sc-width-6 .column.odd {
    width: 50%; }
  .paragraphs-item-two-columns-variable-widths.sc-width-7 .column.odd {
    width: 58.333333%; }
  .paragraphs-item-two-columns-variable-widths.sc-width-8 .column.odd {
    width: 66.666667%; }
  .paragraphs-item-two-columns-variable-widths.sc-width-9 .column.odd {
    width: 75%; }
  .paragraphs-item-two-columns-variable-widths.sc-width-10 .column.odd {
    width: 83.333333%; }
  .paragraphs-item-two-columns-variable-widths.sc-width-11 .column.odd {
    width: 91.666667%; } }

.paragraphs-item-two-columns-variable-widths.full_width .column-items {
  margin-bottom: 0;
  margin-right: auto;
  margin-left: auto; }
  @media only screen and (min-width: 768px) {
    .paragraphs-item-two-columns-variable-widths.full_width .column-items {
      max-width: 720px; } }
  @media only screen and (min-width: 992px) {
    .paragraphs-item-two-columns-variable-widths.full_width .column-items {
      max-width: 960px; } }
  @media only screen and (min-width: 1200px) {
    .paragraphs-item-two-columns-variable-widths.full_width .column-items {
      max-width: 1140px; } }

@media only screen and (max-width: 767px) {
  .front .paragraphs-item-banner,
  .page-node-261 .paragraphs-item-banner {
    padding-top: 20px !important;
    padding-bottom: 160px !important; } }

.paragraphs-item-banner.banner-container {
  background-size: cover;
  background-position: top center; }

.entity-paragraphs-item.lightGrey {
  background-color: #EBEBEB; }

.entity-paragraphs-item.black {
  background-color: black; }
  .entity-paragraphs-item.black .content * {
    color: white; }

.entity-paragraphs-item.darkGrey {
  background-color: #333333; }
  .entity-paragraphs-item.darkGrey .content * {
    color: white; }

.entity-paragraphs-item.grey {
  background-color: #666666; }
  .entity-paragraphs-item.grey .content * {
    color: white; }

.entity-paragraphs-item.brandRed {
  background-color: #D23434; }
  .entity-paragraphs-item.brandRed .content * {
    color: white; }

.paragraphs-item-wysiwyg-editor .content {
  word-break: break-word; }

/* View gallery */
.view-gallery .gallery-item {
  margin-bottom: 30px;
  text-align: center; }

.view-gallery .gallery-wrapper {
  position: relative;
  display: inline-block; }
  .view-gallery .gallery-wrapper:after {
    content: "";
    display: block;
    width: 15px;
    height: 18px;
    background: url(../images/zoom.svg) no-repeat center;
    background-size: cover;
    position: absolute;
    bottom: 10px;
    right: 15px; }
    @media only screen and (max-width: 399px) {
      .view-gallery .gallery-wrapper:after {
        background: none; } }

/* View programm */
.page-programm .main-container > .container {
  max-width: none; }

.page-programm .page-title-wrapper {
  margin-left: auto;
  margin-right: auto; }
  @media only screen and (min-width: 768px) {
    .page-programm .page-title-wrapper {
      max-width: 720px; } }
  @media only screen and (min-width: 992px) {
    .page-programm .page-title-wrapper {
      max-width: 960px; } }
  @media only screen and (min-width: 1200px) {
    .page-programm .page-title-wrapper {
      max-width: 1140px; } }

.page-programm .view-programm .view-filters {
  margin-bottom: 30px; }
  @media only screen and (min-width: 992px) {
    .page-programm .view-programm .view-filters {
      margin-bottom: 50px; } }

.page-programm .view-programm .views-exposed-form {
  margin-left: -15px;
  margin-right: -15px;
  background-color: #EBEBEB;
  padding: 40px 0; }
  @media only screen and (max-width: 767px) {
    .page-programm .view-programm .views-exposed-form {
      padding: 20px 0; } }
  .page-programm .view-programm .views-exposed-form .views-exposed-widgets {
    padding: 0 15px; }
    @media only screen and (max-width: 991px) {
      .page-programm .view-programm .views-exposed-form .views-exposed-widgets {
        max-width: none; } }
    @media only screen and (max-width: 767px) {
      .page-programm .view-programm .views-exposed-form .views-exposed-widgets .views-exposed-widget {
        float: none;
        padding: 1em 0 0; } }
    .page-programm .view-programm .views-exposed-form .views-exposed-widgets .form-select {
      border-radius: .25rem;
      border-color: white;
      height: 41px;
      font-weight: 300; }
      .page-programm .view-programm .views-exposed-form .views-exposed-widgets .form-select option {
        padding: 5px 0; }
    .page-programm .view-programm .views-exposed-form .views-exposed-widgets .form-type-bef-checkbox {
      border-radius: .25rem;
      border-color: white;
      background-color: white;
      padding: .35rem .85rem; }
      .page-programm .view-programm .views-exposed-form .views-exposed-widgets .form-type-bef-checkbox input {
        float: right;
        margin-top: 10px;
        opacity: 0;
        height: 0;
        width: 0; }
      .page-programm .view-programm .views-exposed-form .views-exposed-widgets .form-type-bef-checkbox label {
        font-weight: 300;
        padding-right: 35px;
        position: relative; }
        @media only screen and (max-width: 767px) {
          .page-programm .view-programm .views-exposed-form .views-exposed-widgets .form-type-bef-checkbox label {
            display: block; } }
        .page-programm .view-programm .views-exposed-form .views-exposed-widgets .form-type-bef-checkbox label:before {
          display: block;
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          height: 20px;
          width: 20px;
          background-color: white;
          border-radius: .35rem;
          border: solid 1px #979797;
          box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.5); }
        .page-programm .view-programm .views-exposed-form .views-exposed-widgets .form-type-bef-checkbox label:after {
          content: "";
          display: none;
          position: absolute;
          top: 0;
          right: 0; }
      .page-programm .view-programm .views-exposed-form .views-exposed-widgets .form-type-bef-checkbox input:checked + label:after {
        display: block;
        right: 7px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid black;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg); }
    .page-programm .view-programm .views-exposed-form .views-exposed-widgets input.form-submit {
      margin-top: 0;
      background-color: black;
      border-color: black;
      color: white;
      padding: .5rem .75rem; }
      .page-programm .view-programm .views-exposed-form .views-exposed-widgets input.form-submit:hover, .page-programm .view-programm .views-exposed-form .views-exposed-widgets input.form-submit:focus {
        background-color: black;
        border-color: black;
        color: white; }

.page-programm .view-programm .container {
  padding: 0 15px; }

.page-programm .event-item {
  margin-bottom: 20px;
  padding: 15px 0; }
  @media only screen and (min-width: 768px) {
    .page-programm .event-item {
      margin-bottom: 30px; } }
  @media only screen and (max-width: 768px) {
    .page-programm .event-item col-md-4 {
      margin-bottom: 5px; } }
  .page-programm .event-item span.event-date-1 {
    display: inline-block;
    color: #CF4242;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.6;
    margin-right: 10px; }
    .page-programm .event-item span.event-date-1 br {
      display: none; }
    @media only screen and (min-width: 768px) {
      .page-programm .event-item span.event-date-1 {
        display: block;
        font-weight: 300;
        font-size: 1.3125rem;
        margin-right: 0;
        margin-bottom: 5px; } }
  .page-programm .event-item span.event-date-2 {
    display: inline-block;
    color: #CF4242;
    font-weight: 300;
    font-size: 1.3125rem; }
    @media only screen and (min-width: 768px) {
      .page-programm .event-item span.event-date-2 {
        display: block;
        font-size: 1.875rem;
        margin-bottom: 5px; } }
  .page-programm .event-item p {
    font-size: 1.125rem;
    font-weight: 300;
    margin-bottom: 0; }
    @media only screen and (min-width: 768px) {
      .page-programm .event-item p {
        font-size: 1.3125rem;
        font-weight: 500;
        margin-bottom: 5px; } }
    .page-programm .event-item p a {
      text-decoration: none;
      font-weight: 300; }
      @media only screen and (min-width: 768px) {
        .page-programm .event-item p a {
          font-weight: 500; } }
  .page-programm .event-item h3 {
    line-height: 1;
    position: relative; }
    .page-programm .event-item h3 a {
      color: black;
      font-weight: 500;
      font-size: 1.3125rem; }
      @media only screen and (min-width: 768px) {
        .page-programm .event-item h3 a {
          font-size: 1.875rem;
          font-weight: 300;
          display: inline;
          width: calc(100% - 60px); } }
    @media only screen and (min-width: 768px) {
      .page-programm .event-item h3 .kids-friendly {
        display: inline-block;
        margin-left: 10px;
        margin-bottom: -2px;
        width: 46px;
        height: 36px;
        background: url(../images/bunterhund.png) no-repeat bottom center;
        background-size: cover; } }
  @media only screen and (min-width: 992px) {
    .page-programm .event-item {
      margin-bottom: 50px; } }

/* Primary tabs */
.primary-tabs-wrapper ul.tabs.primary {
  padding: 0;
  margin: 10px 0 15px;
  width: 100%; }
  @media only screen and (max-width: 767px) {
    .primary-tabs-wrapper ul.tabs.primary {
      border: none;
      margin: 20px 0 10px; } }
  @media only screen and (max-width: 767px) {
    .primary-tabs-wrapper ul.tabs.primary li {
      display: block;
      margin: 0 0 10px; } }
  .primary-tabs-wrapper ul.tabs.primary li a {
    font-size: 1rem;
    font-weight: 300;
    background-color: #f6f6f6;
    color: black;
    transition: color ease .3s; }
    @media only screen and (max-width: 767px) {
      .primary-tabs-wrapper ul.tabs.primary li a {
        margin: 0;
        padding: 5px 8px;
        display: block;
        border: solid 1px #9B9B9B;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        border-radius: 5px; } }
  .primary-tabs-wrapper ul.tabs.primary li:hover a, .primary-tabs-wrapper ul.tabs.primary li.active a {
    background-color: white;
    color: #D23434; }

/* System messages */
.messages-wrapper .messages {
  font-size: 0.875rem;
  width: 100%; }

/**
 * Colorbox Custom styles
 */
#colorbox,
#cboxOverlay,
#cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden; }

#colorbox,
#cboxWrapper {
  overflow: visible; }

#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.72); }

#cboxMiddleLeft,
#cboxBottomLeft {
  clear: left; }

#cboxContent {
  position: relative;
  overflow: visible; }

#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

#cboxTitle {
  margin: 0; }

#cboxLoadingOverlay,
#cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/**
 * These elements are buttons, and may need to have additional
 * styles reset to avoid unwanted base styles.
 */
#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  width: auto;
  background: none;
  cursor: pointer; }

#cboxPrevious,
#cboxNext {
  position: absolute;
  top: 50%;
  left: -100px;
  transform: translateY(-50%);
  font-size: 0;
  width: 30px;
  height: 21px; }

#cboxPrevious {
  background: url(../images/arrow-l.svg) no-repeat center; }
  @media only screen and (max-width: 991px) {
    #cboxPrevious {
      left: -30px; } }
  @media only screen and (max-width: 767px) {
    #cboxPrevious {
      left: 15px; } }

#cboxNext {
  background: url(../images/arrow-r.svg) no-repeat center;
  left: auto;
  right: -100px; }
  @media only screen and (max-width: 991px) {
    #cboxNext {
      left: auto;
      right: -30px; } }
  @media only screen and (max-width: 767px) {
    #cboxNext {
      left: auto;
      right: 15px; } }

/**
 * Avoid outlines on :active (mouseclick),
 * but preserve outlines on :focus (tabbed navigating)
 */
#cboxPrevious:active,
#cboxNext:active,
#cboxClose:active,
#cboxSlideshow:active {
  outline: 0; }

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none; }

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0; }

/* Reset box sizing to content-box if theme is using border-box. */
#colorbox,
#cboxContent,
#cboxLoadedContent {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

#colorbox {
  outline: 0; }

#cboxContent,
.cboxPhoto {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0; }

#cboxError {
  padding: 50px;
  border: 1px solid #ccc; }

#cboxTitle {
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  bottom: 0;
  left: 0;
  color: #535353;
  width: 100%;
  padding: 4px 6px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; }

#cboxLoadingOverlay {
  background-color: transparent; }

#cboxLoadingGraphic {
  background: url(../images/loading_animation.gif) no-repeat center center; }

#cboxClose {
  display: none !important; }
  #cboxClose .cbox-close-plain {
    position: absolute;
    font-size: 20px;
    line-height: 18px;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    background: rgba(0, 0, 0, 0.5);
    top: 4px;
    right: 4px;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px; }

.cbox-close-plain:hover {
  color: rgba(255, 255, 255, 0.9);
  background: rgba(0, 0, 0, 0.8); }

html {
  font-size: 16px; }

body {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 1.25rem;
  line-height: 1.5; }

a {
  color: #D23434;
  cursor: pointer;
  display: inline-block; }
  a:hover, a:focus {
    color: #000;
    text-decoration: none;
    outline: none; }

*,
button {
  outline: none; }
  *:hover, *:focus,
  button:hover,
  button:focus {
    outline: none; }

[type=reset],
[type=submit],
button,
html [type=button] {
  -webkit-appearance: none; }

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin: 0 0 0.5em;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: black; }

h1, .h1 {
  color: #000;
  font-size: 2.625rem; }
  @media only screen and (min-width: 992px) {
    h1, .h1 {
      font-size: 3.4375rem; } }
  h1.page-title, .h1.page-title {
    margin: 10px 0;
    font-weight: 600; }
    @media only screen and (min-width: 992px) {
      h1.page-title, .h1.page-title {
        margin: 15px 0; } }

h2, .h2 {
  color: #000;
  font-size: 2.5rem; }
  @media only screen and (max-width: 991px) {
    h2, .h2 {
      font-size: 1.875rem; } }

h3, .h3 {
  font-size: 1.875rem;
  color: #000; }
  @media only screen and (max-width: 991px) {
    h3, .h3 {
      font-size: 1.6875rem; } }

h4, .h4 {
  color: #000;
  font-size: 1.286rem;
  line-height: 1.5; }
  @media only screen and (max-width: 991px) {
    h4, .h4 {
      font-size: 1rem; } }

h5, .h5 {
  color: #000;
  font-size: 1.154rem;
  line-height: 1.5; }

p {
  font-weight: 300;
  letter-spacing: 0.3px;
  line-height: 1.6;
  cursor: default; }
  @media only screen and (min-width: 992px) {
    p {
      font-size: 1.375rem; } }
  p a {
    color: #000;
    text-decoration: underline;
    font-weight: 500; }
    p a:hover, p a:focus {
      text-decoration: none; }
    p a.btn, p a.btn-2x, p .webform-submission-form .form-actions a.webform-button--submit, .webform-submission-form .form-actions p a.webform-button--submit, p a.button, p .btn-white, p .page-search .search-form input[type="submit"], .page-search .search-form p input[type="submit"] {
      text-decoration: none; }
  p.smaller-text {
    font-size: 1.125rem; }
    @media only screen and (min-width: 992px) {
      p.smaller-text {
        font-size: 1.25rem; } }
  p.standout-text {
    font-size: 1.8125rem; }
    @media only screen and (min-width: 992px) {
      p.standout-text {
        font-size: 2.5rem; } }

ul.items.regular, ol.items.regular {
  padding-left: 12px; }

ul.clear-list, ol.clear-list {
  padding: 0;
  margin: 0;
  list-style: none; }
  ul.clear-list li, ol.clear-list li {
    list-style-image: none; }

ul.items.logos, ul.items.trending, ul.items.calendar, ul.items.jobs {
  margin: 0;
  padding: 0;
  list-style: none; }
  ul.items.logos li, ul.items.trending li, ul.items.calendar li, ul.items.jobs li {
    min-height: 56px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ededed;
    position: relative; }

blockquote {
  margin: 30px 0 35px;
  font-style: italic;
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.75rem; }

img {
  max-width: 100%;
  height: auto !important; }

iframe {
  max-width: 100%; }

.intro {
  font-size: 1.286rem; }

.box {
  background-color: white;
  border: solid 1.5px #CF4242;
  border-radius: 7px;
  box-shadow: 1px 1px 11px 1px #9B9B9B;
  padding: 15px; }
  .box * {
    color: #000 !important; }
  @media only screen and (max-width: 767px) {
    .box {
      max-width: 420px;
      margin-left: auto;
      margin-right: auto; } }

.spacer-1x {
  height: 17px;
  line-height: 17px;
  margin: 0; }

.spacer-2x {
  height: 34px;
  line-height: 34px;
  margin: 0; }

.full-width {
  display: inline-block;
  width: 100%;
  margin: 0 0 24px 0;
  padding: 0;
  position: relative;
  box-sizing: border-box; }

.hidden {
  display: none; }

.text-left {
  text-align: left; }

.text-centered {
  text-align: center; }

.text-right {
  text-align: right; }

.text-bold {
  font-weight: 700; }

.text-shadow {
  text-shadow: 1px 0px 1px black, 0 1px 1px black, 1px 0 0px black, 0 -1px 0px black; }

.fl {
  float: left; }

.fr {
  float: right; }

.absolute-bottom {
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  max-width: 100%; }

.display-bmq-bxsm {
  display: none; }
  @media only screen and (max-width: 479px) {
    .display-bmq-bxsm {
      display: inherit; } }

.display-bmq-sm {
  display: none; }
  @media only screen and (max-width: 575px) {
    .display-bmq-sm {
      display: inherit; } }

.display-bmq-md {
  display: none; }
  @media only screen and (max-width: 767px) {
    .display-bmq-md {
      display: inherit; } }

.display-bmq-lg {
  display: none; }
  @media only screen and (max-width: 991px) {
    .display-bmq-lg {
      display: inherit; } }

.display-bmq-xl {
  display: none; }
  @media only screen and (max-width: 1199px) {
    .display-bmq-xl {
      display: inherit; } }

.display-mq-xsm {
  display: none; }
  @media only screen and (min-width: 480px) {
    .display-mq-xsm {
      display: inherit; } }

.display-mq-sm {
  display: none; }
  @media only screen and (min-width: 576px) {
    .display-mq-sm {
      display: inherit; } }

.display-mq-md {
  display: none; }
  @media only screen and (min-width: 768px) {
    .display-mq-md {
      display: inherit; } }

.display-mq-lg {
  display: none; }
  @media only screen and (min-width: 992px) {
    .display-mq-lg {
      display: inherit; } }

.display-mq-xl {
  display: none; }
  @media only screen and (min-width: 1200px) {
    .display-mq-xl {
      display: inherit; } }

.navbar-toggle.mobile-only {
  display: none;
  width: 54px;
  height: 54px;
  margin: 0;
  padding: 0;
  color: white;
  text-align: center;
  background-color: #CF4242 !important;
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1;
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 2;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  box-shadow: 0px 0px 4px 2px #ffffff; }
  .navbar-toggle.mobile-only .icon-bar {
    background-color: white;
    display: block;
    width: 31px;
    height: 3px;
    border-radius: 1px;
    transition: all 0.2s;
    margin: 0 auto; }
    .navbar-toggle.mobile-only .icon-bar.top-bar {
      transform: rotate(0); }
    .navbar-toggle.mobile-only .icon-bar.middle-bar {
      opacity: 1; }
    .navbar-toggle.mobile-only .icon-bar.bottom-bar {
      transform: rotate(0); }
    .navbar-toggle.mobile-only .icon-bar + .icon-bar {
      margin-top: 6px; }
  @media only screen and (min-width: 992px) {
    .navbar-toggle.mobile-only {
      display: none !important; } }

#page-wrapper {
  overflow: hidden; }

#page {
  position: relative; }

.main-container {
  min-height: calc(100vh - 420px);
  padding-top: 30px;
  padding-bottom: 30px; }

#edit-preview {
  display: none; }
