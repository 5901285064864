/* Paragraphs styles */

//Full width section
.paragraphs-items .container.full_width {
    width: 100%;
    max-width: none;
}

//Columns
.field-name-field-columns {
    .column-items {
        margin: 0 auto;
        
        .column {
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 30px;
            margin-left: auto;
            margin-right: auto;
            
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.field-name-field-two-columns {
    .column-items {
        margin: 0 auto;
        
        .column {
            padding-left: 15px;
            padding-right: 15px;
            
            &:last-child {
                margin-bottom: 0;
            }
            
            .entity,
            .content {
                position: relative;
                height: 100%;
            }
        }
    }
}

@include betweenbreakpoints($md,$blg) {
    .paragraphs-item-section-even-sized-columns.columns-2 .column {
        width: 50%;
    }
    
     .paragraphs-item-section-even-sized-columns.columns-3 .column {
        width: 33.33333%;
    }
    
    .paragraphs-item-section-even-sized-columns.columns-4 .column {
        width: 50%;
    }

    .paragraphs-item-section-even-sized-columns.columns-5 .column,
    .paragraphs-item-section-even-sized-columns.columns-6 .column {
        width: 33.33333%;
    }

}

@include breakpoint($lg) {
    .paragraphs-item-section-even-sized-columns.columns-2 .column {
        width: 50%;
    }
    
     .paragraphs-item-section-even-sized-columns.columns-3 .column {
        width: 33.33333%;
    }
    
    .paragraphs-item-section-even-sized-columns.columns-4 .column {
        width: 25%;
    }
    
    .paragraphs-item-section-even-sized-columns.columns-5 .column {
        width: 20%;
    }
    
    .paragraphs-item-section-even-sized-columns.columns-6 .column {
        width: 16.6666666%;
    }

}

//Full width section with one column - CTA
.paragraphs-item-section-even-sized-columns.columns-1.full_width {
    .field-name-field-columns {
        
        @include breakpoint($md) {
            max-width: 720px;
        }

        @include breakpoint($lg) {
            max-width: 960px;
        } 

        @include breakpoint($xl) {
            max-width: 1140px;
        }
        
        .column {
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 0;
            margin-right: auto;
            margin-left: auto;
        }
    }
}

//Two columns with variable widths
.paragraphs-item-two-columns-variable-widths {
    @include breakpoint($lg) {
        
        &.fc-width-1 {
            .column.even {
                width: 8.333333%;
            }
        }
        
        &.fc-width-2 {
            .column.even {
                width: 16.666667%;
            }
        }
        
        &.fc-width-3 {
            .column.even {
                width: 25%;
            }
        }
        
        &.fc-width-4 {
            .column.even {
                width: 33.333333%;
            }
        }
        
        &.fc-width-5 {
            .column.even {
                width: 41.666667%;
            }
        }
        
        &.fc-width-6 {
            .column.even {
                width: 50%;
            }
        }
        
        &.fc-width-7 {
            .column.even {
                width: 58.333333%;
            }
        }
        
        &.fc-width-8 {
            .column.even {
                width: 66.666667%;
            }
        }
        
        &.fc-width-9 {
            .column.even {
                width: 75%;
            }
        }
        
        &.fc-width-10 {
            .column.even {
                width: 83.333333%;
            }
        }
        
        &.fc-width-11 {
            .column.even {
                width: 91.666667%;
            }
        }
        
        &.fc-width-12,
        &.sc-width-12 {
            .column {
                width: 100%;
            }
        }
        
        
        &.sc-width-1 {
            .column.odd {
                width: 8.333333%;
            }
        }
        
        &.sc-width-2 {
            .column.odd {
                width: 16.666667%;
            }
        }
        
        &.sc-width-3 {
            .column.odd {
                width: 25%;
            }
        }
        
        &.sc-width-4 {
            .column.odd {
                width: 33.333333%;
            }
        }
        
        &.sc-width-5 {
            .column.odd {
                width: 41.666667%;
            }
        }
        
        &.sc-width-6 {
            .column.odd {
                width: 50%;
            }
        }
        
        &.sc-width-7 {
            .column.odd {
                width: 58.333333%;
            }
        }
        
        &.sc-width-8 {
            .column.odd {
                width: 66.666667%;
            }
        }
        
        &.sc-width-9 {
            .column.odd {
                width: 75%;
            }
        }
        
        &.sc-width-10 {
            .column.odd {
                width: 83.333333%;
            }
        }
        
        &.sc-width-11 {
            .column.odd {
                width: 91.666667%;
            }
        }
    }
}

//Full width with variable widths 
.paragraphs-item-two-columns-variable-widths.full_width {
    .column-items {
        margin-bottom: 0;
        margin-right: auto;
        margin-left: auto;

        @include breakpoint($md) {
            max-width: 720px;
        }

        @include breakpoint($lg) {
            max-width: 960px;
        } 

        @include breakpoint($xl) {
            max-width: 1140px;
        }
        
        .column {
            
        }
    }
}


// Banner styles
.front .paragraphs-item-banner,
.page-node-261 .paragraphs-item-banner {
        @include beforebreakpoint($bmd) {
            padding-top: 20px !important;
            padding-bottom: 160px !important;
    }
}

.paragraphs-item-banner {
    &.banner-container {
        background-size: cover;
        background-position: top center;
    }
}

//Background colors styles
.entity-paragraphs-item.lightGrey {
    background-color: $c_greybgr;
}

.entity-paragraphs-item.black {
    background-color: black;

    .content {
        * {
            color: white;
        }
    }
}

.entity-paragraphs-item.darkGrey {
    background-color: $dark-grey;

    .content {
        * {
            color: white;
        }
    }
}

.entity-paragraphs-item.grey {
    background-color: $mid-grey;

    .content {
        * {
            color: white;
        }
    }
}

.entity-paragraphs-item.brandRed{
    background-color: $red;

    .content {
        * {
            color: white;
        }
    }
}

// Paragraph WYSIWYG Editor
.paragraphs-item-wysiwyg-editor {
    .content {
        word-break: break-word;
    }
}