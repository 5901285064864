/* Landing page content type styles*/
.node-type-stadtnaturevent {
    .region-content-wrapper {
        padding: 0;
        
        @include beforebreakpoint($bmd) {
            padding: 0;
        }
    }
    
    .main-container {
        > .container {
            max-width: none;
            width: 100%;
        }
    }
}

.event-ajax-container {
    background-color: white;
    opacity: 0;
    transition: opacity ease .23s;
    
    &.open {
        height: calc(100% - 244px);
        position: absolute;
        top: 244px;
        left: 0;
        width: 100%;
        opacity: 1;
        z-index: 10;
    }
}

.node-stadtnaturevent {
    .event-top,
    .event-middle,
    .event-bottom {
        .container {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    
    .event-top {
        padding: 30px 0;
        
        .col-12 {
            padding-bottom: 15px;
            text-align: right;
            
            a.ajax_event-window-close {
                display: inline-block;
                color: black;
                font-size: 1rem;
                font-weight: 300;
                
                .mobile-close,
                .desktop-close {
                    display: none;
                }
                
                @include beforebreakpoint($bmd) {
                    .mobile-close {
                        display: inline-block;
                    }
                }

                @include breakpoint($md) {
                    .desktop-close {
                        display: inline-block;
                    }
                }
            }
        }
        
        .col-left {
            @include beforebreakpoint($bmd) {
                margin-bottom: 20px;
            }
            
            .event-node-date-wrapper {
                font-size: 1.625rem;
                font-weight: 300;
                text-transform: uppercase;
                color: $light-red;
                margin-bottom: 20px;
            }

            h1 {
                font-size: 2.25rem;

                @include breakpoint($lg) {
                    font-size: 2.5rem;
                }
            }

            .event-price-wrapper,
            .event-contact-info-wrapper,
            .event-contact-wrapper,
            .event-place-wrapper {
                font-size: 1rem;
                font-weight: 400;

                .field-label {
                    font-weight: 500;
                }
            }
        }
        
        .single-column {
            .col-left {
                flex: 0 0 100%;
                max-width: 100%;
                width: 100%;
            }
        }
    } //event-top
    
    .event-middle {
        padding: 30px 0;
        background-color: $c_greybgr;
        
        p.event-name {
            font-size: 1.25rem;
            text-transform: uppercase;
            
            @include breakpoint($lg) {
                font-size: 1.375rem;
            }   
        }
        
        h3.event-subtitle {
            font-size: 1.25rem;
            font-weight: 500;
            margin-bottom: 20px;
            
            @include breakpoint($lg) {
                font-size: 1.375rem;
            } 
        }
        
        .event-description {
            font-size: 1.25rem;
            letter-spacing: 0.25px;
            
            @include breakpoint($lg) {
                font-size: 1.375rem;
            } 
        }
    } // event-middle
    
    .event-bottom {
        padding: 30px 0;
        
        .event-link {
            font-size: 1rem;
            margin-bottom: 15px;
            
            a {
                color: black;
                font-weight: 300;
                text-decoration: underline;
            }
        }
    }
}
