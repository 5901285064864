/* Primary menu */


// The menu itself
    
#navbar-collapse {

    ul.menu {
        margin: 0;
        padding: 0;

        > li {
            list-style: none;
            list-style-image: none;
            padding: 0;
            margin: 0 1rem 0 0;
            
            &.last {
                margin-right: 0;
            }
            
            > a {
                
            }
            
            a, span {
                font-size: 1.375rem;
                font-weight: 300;
                 color: black;
                transition: color ease .23s;
                
                &:hover, &:focus,
                &.active {
                    color: $light-grey;
                }
            }

            span {
                cursor: default;
            }

            &.dropdown:hover {
                @include breakpoint($md) {
                    ul.dropdown-menu {
                        display: block;
                    }
                }
            }

            &.dropdown.show {
                a.dropdown-toggle:after {
                    transform: rotate(-180deg);
                }
            }


            ul.dropdown-menu {
                border:none;
                background: white;
                padding: 10px 0;
                position: relative;

                @include breakpoint($lg) {
                    position: absolute;
                    margin: 0;

                }
                
                li {
                    list-style: none;
                    list-style-image: none;
                    margin: 0;
                    padding: 0;

                    a {
                        font-size: 0.9375rem;
                        display: block;
                        padding: 5px 0;
                    } 
                }
            }

            &.dropdown.show {
                ul.dropdown-menu {
                    display: block;
                }
            }
        } //li

         li.dropdown-item {

            a{
                transition: none;

                &:hover {
 
                    background: none;
                }

            }

             &:hover {
                 background: none;
             }

             &.active {
                 background: none;

                 a{

                 }
             }
        }


        @include breakpoint($lg) {
            width: 100%;
            flex-direction: row;

            > li {
                display: inline-block;

                &:last-child {
                    margin: 0;
                }

                a, span {
     
                }
            }

            li.dropdown-item {


                a{

                }
            }
        } //$lg
    }
}
