/* Block local tasks */

.block-local-tasks-block {
    margin: 30px 0 15px;
    
    ul.tabs.primary {
        li {
            a {
                font-size: 16px;
            }
        }
    }
}