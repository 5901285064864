/* 
Region top bar styles
*/

#top-bar {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    z-index: 10;
    background-color: white;
    height:0;
    opacity:0;
    overflow: hidden;
    transition: all ease-in-out .23s;
    padding: 30px 0 0;
    
    .region {
        padding: 0;
        position: relative;
    }
    
    @include breakpoint($lg) {
        padding: 40px 0 0;
        display: block;
        position: relative;
        background-color: transparent;
        height: auto;
        opacity:1;
        z-index: auto;
        overflow: inherit;
    }
    
    &.show {
        height:100%;
        opacity:1;
        background-color: $c_theme;
    }
    
    .close-wrapper {
        @include breakpoint($lg) {
            display: none;
        }
    }
    
    .container,
    .row,
    .col-12,
    .region {
        height: 100%;
    }
    
    .block {
        height: auto;
        
        &.top-bar-mobile-menu-block {
            padding-top: 100px;
            width: 100%;
        }
    }
}
