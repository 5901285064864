/* Block system main block */

.block-system-main-block > .container-fluid {
    @include breakpoint($lg) {
        padding-top: 20px;
    }
}

.page-node-civi_fed_organisation .block-system-main-block > .container-fluid {
    padding-top: 0;
}