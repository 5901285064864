/* View programm */

.page-programm {
    .main-container {
        >.container {
            max-width: none;
        }
    }
    
    .page-title-wrapper {
        margin-left: auto;
        margin-right: auto;
        
        @include breakpoint($md) {
            max-width: 720px;
        }
        
        @include breakpoint($lg) {
            max-width: 960px;
        }
        
        @include breakpoint($xl) {
            max-width: 1140px;
        }
    }
    
    $custom-checkbox-border-color: #979797;

    .view-programm {
        .view-filters {
            margin-bottom: 30px;
            
            @include breakpoint($lg) {
                margin-bottom: 50px;
            }
        }
        
        .views-exposed-form {
            margin-left: -15px;
            margin-right: -15px;
            background-color: $c_greybgr;
            padding: 40px 0;
            
            @include beforebreakpoint($bmd) {
                padding: 20px 0;
            }
            
            .views-exposed-widgets {
                padding: 0 15px;
                
                @include beforebreakpoint($blg) {
                    max-width: none;
                }
                
                .views-exposed-widget { 
                    
                    @include beforebreakpoint($bmd) {
                        float: none;
                        padding: 1em 0 0;
                    }
                }

                .form-select {
                    border-radius: .25rem;
                    border-color: white;
                    height: 41px;
                    font-weight: 300;
                    
                    option {
                        padding: 5px 0;
                    }
                }
                
                .form-type-bef-checkbox {
                    border-radius: .25rem;
                    border-color: white;
                    background-color: white;
                    padding: .35rem .85rem;
                    
                    input {
                        float:right;
                        margin-top: 10px;
                        opacity: 0;
                        height: 0;
                        width: 0;
                    }
                    
                    label {
                        font-weight: 300;
                        padding-right: 35px;
                        position: relative;
                        
                        @include beforebreakpoint($bmd) {
                            display: block;
                        }
                        
                        &:before {
                            display: block;
                            content: "";
                            position: absolute;
                            top: 0;
                            right: 0;
                            height: 20px;
                            width: 20px;
                            background-color: white;
                            border-radius: .35rem;
                            border: solid 1px $custom-checkbox-border-color;
                            box-shadow: inset 0px 1px 4px rgba(0,0,0,0.5);
                            
                        }
                        
                        &:after {
                            content:"";
                            display: none;
                            position: absolute;
                            top: 0;
                            right: 0;
                        }
                    }
                    
                    input:checked + label:after {
                          display: block;
                          right: 7px;
                          top: 5px;
                          width: 5px;
                          height: 10px;
                          border: solid black;
                          border-width: 0 3px 3px 0;
                          transform: rotate(45deg);
                    }
                }
                
                input.form-submit {
                    margin-top: 0;
                    background-color: black;
                    border-color: black;
                    color: white;
                    padding: .5rem .75rem;
                    
                    &:hover, &:focus {
                        background-color: black;
                        border-color: black;
                        color: white;
                    }
                }
            }
        }
        
        .container {
            padding: 0 15px;
        }
    }
    
    .event-item {
        margin-bottom: 20px;
        padding: 15px 0;
        
        @include breakpoint($md) {
            margin-bottom: 30px;
        }
        
        col-md-4 {
            @include beforebreakpoint($md) {
                margin-bottom: 5px;
            }
        }
        
        span.event-date-1 {
            display: inline-block;
            color: $light-red;
            font-weight: 500;
            font-size: 1.125rem;
            line-height: 1.6;
            margin-right: 10px;
            
            br {
                display: none;
            }
            
            @include breakpoint($md) {
                display: block;
                font-weight: 300;
                font-size: 1.3125rem;
                margin-right: 0;
                margin-bottom: 5px;
            }
        }

        span.event-date-2 {
            display: inline-block;
            color: $light-red;
            font-weight: 300;
            font-size: 1.3125rem;
            
            @include breakpoint($md) {
                display: block;
                font-size: 1.875rem;
                margin-bottom: 5px;
            }
        }
        
        p {
            
            font-size: 1.125rem;
            font-weight: 300;
            margin-bottom: 0;
            
            @include breakpoint($md) {
                font-size: 1.3125rem;
                font-weight: 500;
                margin-bottom: 5px;
            }
            
            a {
                text-decoration: none;
                font-weight: 300;
                
                @include breakpoint($md) {
                    font-weight: 500;    
                }
            }
        } 
        
        h3 {
            line-height: 1;
            position: relative;
            
            a {
                color: black;
                font-weight: 500;
                font-size: 1.3125rem;
                
                @include breakpoint($md) {
                    font-size: 1.875rem;
                    font-weight: 300;
                    display: inline;
                    width: calc(100% - 60px);
                }
            }
            
            @include breakpoint($md) {
                .kids-friendly {
                    display: inline-block;
                    margin-left: 10px;
                    margin-bottom: -2px;
                    width: 46px;
                    height: 36px;
                    background: url(../images/bunterhund.png) no-repeat bottom center;
                    background-size: cover;
                }
            }
        }
        
        @include breakpoint($lg) {
            margin-bottom: 50px;
        }
    }
}
