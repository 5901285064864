/* 
Iconized links block styles
*/

.top-bar-iconized-links-block {
    background-color: transparent;
    
    @include breakpoint($lg) {
        background-color: white;    
    }
    
    .social-links {
        float: left;
        display: inline-block;
        
        a {
            font-size: 1.5rem;
            line-height: 1.5rem;
            color: white;
            margin-right: 15px;
            display: inline-block;
            
            &:last-child {
                margin: 0;
            }
            
            &.fb-link {
                width: 22px;
                height: 24px;
                position: relative;
                
                .fab {
                    position: absolute;
                    top: 4px; 
                    
                    &:before {
                        display: inline-block;
            
                    }
                }
            }
            
            &.ig-link {
                font-size:1.65rem;
                line-height: 1.2;
                position: relative;
                width: 30px;
                height: 24px;
                
                .fab {
                    position: absolute;
                    top: 4px;
                    
                    
                    &:before {
                        display: inline-block;
            
                    }
                }
            }
        }
        
        @include breakpoint($lg) {
            float:none;
            margin-right: 45px;
            
            a {
                color: black;
            }
        }
    }
    
    .user-links {
        float: right;
        display: inline-block;
        
        a {
            display: inline-block;
            margin-right: 15px;
            
            &:last-child {
                margin: 0;
            }
            
            &.user-link-toggle {
                margin-right: 5px;
                width: 40px;
                text-align: center;
            }
            
            &.newsletter-link {
                margin-right: 5px;
                width: 40px;
                text-align: center;
            }
            
            &.search-link {
                width: 36px;
                text-align: center;
            }
            
            &.user-link-toggle.active,
            &.newsletter-link.active,
            &.search-link.active {
                
                @include breakpoint($lg) {
                    background: $c_greybgr;
                }
            }
            
        }
        
        span.desktop-only {
            display: none;
        }
        
        @include breakpoint($lg) {
            float:none;
            span.desktop-only {
                display: inline-block;
            }
            
            span.mobile-only {
                display: none;
            }
        }
    }
    
    @include breakpoint($lg) {
        float:right;
    }
}
