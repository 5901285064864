/* Form */
//button border variables
$btn-default-border: #a2a2a2;
$btn-default-bgrcolor: #e6e6e6;

.form-control {
    height: 34px;
    padding: 8px 15px;
    font-size: 1rem;
    line-height: 19px;
    color: $c_text;
    box-sizing: border-box;
    border: 1px solid $c_border-v2;
    border-radius: 0;
    transition: none;
    font-weight: 400;

    &:hover,
    &:focus {
            box-shadow: none;

    }
}

.form-text,
.form-select,
.form-search,
.form-email,
.form-tel {
        display: block;
        width: 100%;
        margin: 0;
        font-size: 1rem;
        @extend .form-control;
}

.form-select{
    cursor: pointer; 
    padding: 4px 15px;
}

textarea {
    font-size: 1rem;
    font-weight: 400;
    padding: 10px;
}

.form-type-radio {
    input[type="radio"], 
    input[type="checkbox"] {
        margin: 5px;
    }
    
    label {
        max-width: 90%;
        width: auto !important;
    }
}

.webform-element--title-inline > label {
    @include beforebreakpoint($bmd) {
        margin-bottom: 5px;
        display: block;
    }
    
}

label {
        display: block;
        margin: 0;
        font-weight: 300;
        font-size: 1rem;
}

.form-item legend {
    font-weight: 600;
    font-size: 1.286rem;
}

.form-item, 
.form-actions {
        margin: 15px 0;
}


.button,
.btn, 
.form-submit, 
#popup-buttons button {
    background-color: white;
    color: $light-red;
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid $light-red;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    line-height: 1.42857143;
    text-transform: none;
    outline: none;
    cursor: pointer;
    
    &:hover, &:focus {
        background-color: white;
        color: $light-red;                
        text-decoration: none;
        box-shadow: inset 0px 1px 3px rgba(0,0,0,0.5);
    }
    
    @include beforebreakpoint($md) {
        margin-bottom: 15px;
    }
}

.button + .button,
.btn + .btn,
.button + .button + .button,
.btn + .btn + .btn {
    margin-left: 10px; 
}

.btn-2x {
    @extend .btn;
    
    text-align: center;
    font-size: 1.286rem;
    font-weight: 600;
    line-height: 1.42;
    padding: 6px 12px;
}

.btn-white {
    @extend a.button;
    font-weight: 400;
    
    &:hover, &:focus {
        background-color:$btn-default-bgrcolor;
        color: $c_text;                
        box-shadow: none;
        text-decoration: none;
    }
}


nav.pager {
    margin: 0 0 15px;
    
    
    .pager__items {
        text-align: right;
        margin: 0;
        padding: 0;
        
        li.pager__item {
            font-size: 1rem;
            font-weight: 400;
            border: 1px solid $light-grey;
            margin-right: 3px;
            margin-bottom: 10px;
            padding: 5px 10px;
            display: inline-block;
            background-color: white;
            &:hover {
                background-color: $c_theme;
            }
            
            &.is-active {
                font-weight: 600;
                cursor: default;
            }
            
            &.pager__item--next,
            &.pager__item--previous,
            &.pager__item--last {
                background: white;
                padding: 0;
                
                &:hover {
                    background-color: $c_theme;
                }
                
                a {
                    background: transparent;
                    color: $blue;
                    padding: 5px 10px;
                    display: inline-block;
                }
            }
        }
    }
}

// Search form styles
.page-search {
    .search-form {
        input[type="text"] {
            max-width: 250px;
            height: 36px;
            display: inline-block;
        }
        
        input[type="submit"] {
            @extend .btn-white;
        }
        
        .search-help-link {
            margin: 10px 0;
            display: inline-block;
        }
        
        label {
            @include beforebreakpoint($bsm) {
                display: block;
                margin-bottom: 5px;
            }
         }
        
    }
    
    
    .item-list {
        margin-bottom: 30px;
    }
    
    .search-advanced summary {
        padding: 10px;
    }
    
    .search-form+h2 {
        margin: 30px 0 20px;
    }
    
    .search-results {
        padding: 0;
        
        li {
            margin: 0 0 15px;
            padding: 0 0 14px;
            border-bottom: solid 1px $c_border-v3;
        }
        
        .search-info {
            span.username {
                display: none;
            }
        }
    }
}

// Webform - Contact form
.webform-submission-form {
    legend {
        margin: 0 0 15px;
    }
    
    .form-item {
        margin: 0 0 15px 0;
    }
    
    .webform-element--title-inline {  
        @include breakpoint($md) {
            display: flex;
        }
        
        &.radios--wrapper {
            @include breakpoint($md) {
                display: block;
            }
            
            legend,
            .fieldset-wrapper {
                float:left;
                margin: 10px 0;
                font-weight: 300;
                
                @include breakpoint($md) {
                    width:50%;
                    display: inline-block;
                }
            }
        }
    }
    
    .form-item.form-type-textarea {
        display: block;
    }
    
    .form-actions {
        text-align: right;
        padding-bottom: 30px;
        
        .webform-button--submit {
            @extend .btn;

            &:hover, &:focus {
                background-color: $c_text;
                border-color: $c_text;
            }
        }
    }
    
    .webform-element--title-inline .form-text, 
    .webform-element--title-inline .form-select,  
    .webform-element--title-inline .form-email, 
    .webform-element--title-inline .form-tel {
        @include breakpoint($md) {
            width:50%;
            display: inline-block;
        }
    }
    
    .form-type-checkbox {
        display: block;
        
        .description {
            margin: 10px 0 0;
        }
    }
    
    .form-item {
        
        label {
            margin-bottom: 5px;
        }
        
        &.webform-element--title-inline {
            
            label {

                @include breakpoint($md) {
                    width:50%;
                    display: inline-block;
                }
            }
        }
    }
    
    .form-type-textarea label {
        width: 100%;
        display: block;
    }
}

.form-item .description {
    font-size: 0.875rem;
    margin: 10px 0 0;
}