// Basic theme styles
html {
    font-size: 16px;
}

body {
    color: $c_text;
    font-family: $f_roboto;
    font-size: 1.25rem;
    line-height: 1.5;
}

a {
    color: $c_theme;
    cursor:pointer;
    display: inline-block;
    
    &:hover, &:focus {
        color: $c_text;
        text-decoration: none;
        outline: none;
    }
}

*,
button {
    outline: none;
        
    &:hover,
    &:focus {
        outline: none;
    }
}

[type=reset], 
[type=submit], 
button, 
html [type=button] {
    -webkit-appearance: none;
}
   
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin:0 0 0.5em;
	font-family: $f_roboto;
    font-weight: 300;
	color: black;
}

h1, .h1 {
	color: $c_text;
    font-size: 2.625rem;
    
    @include breakpoint ($lg) {
        font-size: 3.4375rem;
    }
    
    &.page-title {
        margin: 10px 0;
        font-weight: 600;
        
        @include breakpoint ($lg) {
            margin: 15px 0;
        }
    }
    
}

h2, .h2 {
	color: $c_text;
    font-size: 2.5rem;
    
    @include beforebreakpoint($blg) {
        font-size: 1.875rem;
    }
}

h3, .h3 {
    font-size: 1.875rem;
	color: $c_text;
    
    @include beforebreakpoint($blg) {
        font-size: 1.6875rem;
    }
}

h4, .h4 {
	color: $c_text;
    font-size: 1.286rem;
    line-height: 1.5;
    
    @include beforebreakpoint($blg) {
        font-size: 1rem;
    }
}

h5, .h5 {
	color: $c_text;
    font-size: 1.154rem;
    line-height: 1.5;
    
    
}

p {
    font-weight: 300;
    letter-spacing: 0.3px;
    line-height: 1.6;
    cursor: default;
    
    @include breakpoint($lg) {
        font-size: 1.375rem;
    }
    
    a {
        color: $c_text;
        text-decoration: underline;
        font-weight: 500;
        
        &:hover, &:focus {
            text-decoration: none;
        }
        
        &.btn, 
        &.button {
            text-decoration: none;
        }
    }
    
    &.smaller-text {
        font-size: 1.125rem;
        
        @include breakpoint($lg) {
            font-size: 1.25rem;
        }
    }
    
    &.standout-text {
        font-size: 1.8125rem;
                
        @include breakpoint($lg) {
            font-size: 2.5rem;
        }
    }
}

ul, ol {
    
    &.items.regular {
        padding-left: 12px;
    }
    
    &.clear-list {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
            list-style-image: none;
        }
    }
}

ul.items {
    
    &.logos,
    &.trending,
    &.calendar,
    &.jobs {
        margin: 0;
        padding: 0;
        list-style: none;
        
        li {
            min-height: 56px;
            margin-bottom: 10px;
            border-bottom: 1px solid $c_border-v3;
            position: relative;
        }
    }
}

blockquote {
    margin: 30px 0 35px;
    font-style: italic;
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.75rem;
}

img{
    max-width: 100%;
    height: auto !important;

}

iframe {
    max-width: 100%;
}

.intro {
    font-size: 1.286rem;
}

.box {
    background-color: white;
    border: solid 1.5px $light-red;
    border-radius: 7px;
    box-shadow: 1px 1px 11px 1px $light-grey;
    padding: 15px;
    
    * {
        color: $c-text !important;
    }
    
    @include beforebreakpoint($bmd) {
        max-width: 420px;
        margin-left: auto;
        margin-right: auto;
    }
}

.spacer-1x {
    height: 17px;
    line-height: 17px;
    margin: 0;
}

.spacer-2x {
    height: 34px;
    line-height: 34px;
    margin: 0;
}


.full-width {
    display: inline-block;
    width: 100%;
    margin: 0 0 24px 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;

}

.hidden {
    display: none;
}

.text-left {
    text-align: left;
}

.text-centered {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-bold {
    font-weight: 700;
}

.text-shadow {
    text-shadow: 1px 0px 1px black, 0 1px 1px black, 1px 0 0px black, 0 -1px 0px black;
}

.fl {
    float: left;
}

.fr {
    float:right;
}

.absolute-bottom {
    position:absolute; 
    bottom:0; 
    margin:0 auto;
    max-width: 100%;
}

.display-bmq-bxsm {
    display: none;
    @include beforebreakpoint($bxsm) {
        display: inherit;
    }
}

.display-bmq-sm {
    display: none;
    @include beforebreakpoint($bsm) {
        display: inherit;
    }
}

.display-bmq-md {
    display: none;
    @include beforebreakpoint($bmd) {
        display: inherit;
    }
}

.display-bmq-lg {
    display: none;
    @include beforebreakpoint($blg) {
        display: inherit;
    }
}

.display-bmq-xl {
    display: none;
    @include beforebreakpoint($bxl) {
        display: inherit;
    }
}

.display-mq-xsm {
    display: none;
    @include breakpoint($xsm) {
        display: inherit;
    }
}

.display-mq-sm {
    display: none;
    @include breakpoint($sm) {
        display: inherit;
    }
}

.display-mq-md {
    display: none;
    @include breakpoint($md) {
        display: inherit;
    }
}

.display-mq-lg {
    display: none;
    @include breakpoint($lg) {
        display: inherit;
    }
}

.display-mq-xl {
    display: none;
    @include breakpoint($xl) {
        display: inherit;
    }
}

.navbar-toggle.mobile-only { //mobile menu toggle button
	display: none;
	width: 54px;
	height: 54px;
	margin: 0;
	padding: 0;
	color: white;
	text-align: center;
    background-color: $light-red !important;
	filter: alpha(opacity=100);
    -moz-opacity: 1;
    opacity: 1;
	position: fixed;
	bottom: 15px;
	right: 15px;
	z-index: 2;
    @include border-radius(50%);
    box-shadow: 0px 0px 4px 2px #ffffff;
    
    .icon-bar {
        background-color: white;
        display: block;
        width: 31px;
        height: 3px;
        border-radius: 1px;
        transition: all 0.2s;
        margin: 0 auto;

        &.top-bar {
            transform: rotate(0);
        }

        &.middle-bar {
            opacity: 1;
        }

        &.bottom-bar {
            transform: rotate(0);
        }

        +.icon-bar {
            margin-top: 6px;
        }
    }
    
    @include breakpoint($lg) {
        display: none !important;
    }
}

#page-wrapper {
    overflow:hidden;
}

#page {
    position: relative;
}

.main-container {
    min-height: calc(100vh - 420px); //Min height based on visual port height and header+footer
    padding-top: 30px;
    padding-bottom: 30px;
}

#edit-preview { display: none; }


